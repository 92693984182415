// ptn: header-image
.part-header-image {
  padding-top: 1px; // Without this, we're not able to use margin-top on h1 without it disabling the img with negative margin.
  position: relative;
  height:auto;
  margin-top: calc(round(-5vw)); // go 'under' the jagged placard header
  margin-bottom: calc(round(4vw)); // hmmm.

  img {
    width: 100%;
    display: block;
  }

  .title {
    position: absolute;
    padding: 1vw 2vw;

    left: 0;
    width: 80vw;
    bottom: -3vw;

    h1 {
      // color: black;
      font-size: clamp(1.4rem, 3.6vw, 4rem);
      margin:0;
      padding:0;
      text-align: left;
      line-height: 1.6;
    }
    span {
      line-height: 1.4; // this creates a gap because the h1 has line-height:2
      padding-left: 1rem;
      padding-right: 0;
      display: inline-block;
      color:white;
      background: var(--section-colour, var(--dark-purple));
      // text-transform: uppercase;
      position: relative;
      $flagSize: calc(round(1.2em));
      &:after {
        background: var(--section-colour, var(--dark-purple));
        clip-path: polygon(0 0, 100% 0, 0 100%);
        width: $flagSize;
        height: 100%;
        right: -$flagSize;
        content: '';
        display:block;
        position: absolute;
        top: 0;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .ptn-header-image {
    margin-bottom: 4vw;
    .title {
      bottom: -4vw;
      width: 70vw;
      left: 0;
      &::before {
        height: 3vw;
        top: -3vw;
      }
      &::after {
        height: 10vw;
      }
    }
  }

}
