@mixin placard($style: 'default', $hover: no) {
  background: var(--placard-bg-default);
  background-image: linear-gradient(90deg,
    var(--placard-bg-default) 0%, var(--placard-bg-default) 50%,
    var(--placard-bg-hover) 50%, var(--placard-bg-hover) 100%);
  // padding: 7%; // allow for the wonkiness.
  background-size: 200% 100%;
  transition: 0.3s background-position-x;

  @if $style == default {
    clip-path: polygon(100% 2%,0% 4%,0% 100%,100% 97%);
  }
  @if $style == flat-top {
    clip-path: polygon(100% 0%,0% 0%,0% 100%,100% 97%);
  }
  @if $style == alt {
    clip-path: polygon(100% 3%, 0% 0%, 0% 100%, 100% 97%);
  }

  @if $hover != no {
    &:hover { background-position-x: -100%; }
  }
}

@mixin defaultColourVars() {
  --colour-text: var(--soft-black);
  --colour-heading: var(--bright-purple);
  --colour-link: var(--meh-purple);
  --colour-link-decoration: var(--strong-orange);
  --colour-link-hover: var(--bright-magenta);
  --link-decoration-thickness: 2px;
  --colour-primary-button-text: var(--cream);
  --colour-primary-button-text-hover: white;
  --colour-primary-button-text-active: var(--bright-orange);
  --colour-primary-button-border: var(--bright-purple);
  --colour-primary-button-border-focus: var(--dark-magenta);
  --colour-primary-button-border-active: var(--dark-purple);
  --colour-primary-button-bg: var(--bright-purple);

  --colour-secondary-button-border: var(--bright-magenta);
  --colour-secondary-button-bg: transparent;
  --colour-secondary-button-bg-hover: white;
  --colour-secondary-button-bg-active: var(--dark-purple);
  --colour-secondary-button-text: var(--bright-purple);
  --colour-secondary-button-text-hover: var(--dark-magenta);
}

// This does not set display:inline-block (why not?)
@mixin button() {
  border: solid 2px var(--colour-primary-button-border);
  border-radius: 4px;
  background: var(--colour-primary-button-bg);
  color: var(--colour-primary-button-text);
  padding: 0.5rem 1.5rem;
  font-family: AtkinsonHyperlegible;
  font-weight: bold;
  // text-transform: uppercase;
  font-size: 1.1em;

  &:hover {
    border-color: var(--dark-magenta);
    color: var(--colour-primary-button-text-hover);
  }
  &:focus {
    border-color: var(--colour-primary-button-border-focus);
  }
  &:active {
    border-color: var(--colour-primary-button-border-active);
    color: var(--colour-primary-button-text-active);
  }

  // Tweaks for 'other' buttons
  &.other {
    background: var(--colour-secondary-button-bg);
    border-color: var(--colour-secondary-button-border);
    color: var(--colour-secondary-button-text);
    &:focus,
    &:hover {
      background: var(--colour-secondary-button-bg-hover);
      color: var(--colour-secondary-button-text-hover);
    }
    &:active {
      background: var(--colour-secondary-button-bg-active);
    }

  }
}

@mixin whiteLinks() {
  --colour-link-decoration: var(--cream);
  --colour-link-hover: var(--white);
  --colour-link: var(--cream);
  --colour-primary-button-bg: var(--bright-magenta);
  --colour-secondary-button-border: var(--bright-magenta);
}
