label { display: block; }
input[type=text],
input[type=number],
input[type=date],
input[type=datetime],
input[type=password],
input[type=email],
textarea,
select {
  border: solid 2px var(--dark-magenta);
  border-radius: 4px;
  background: #fafafa;
  padding: 0.25rem 0.5rem;
  width: 100%;
  font-family: "Source Sans 3";
  font-weight: normal;
  line-height: 1.2;

  &:focus {
    border-color: var(--bright-magenta);
    background: white;
    outline: none;
  }
}
input[type=radio],
input[type=checkbox] {
  display: inline-block;
  &+label {
    display: inline;
  }
}
form {
  font-size: 1rem;
}
form input[type=submit],
form button {
  @include button;

  // normally like send on right.
  display: block;
  margin-left: auto;
  margin-right: 0;
}

fieldset {
  border: solid 2px var(--dark-magenta);
  border-radius: 5px;
}

