@import 'colours';
@import 'css-vars';
@import 'mixins';
@import 'hacks';
@import 'functional';
@import 'forms';
@import 'icons';
@import 'fonts';
@import 'ff-scorecard';
$pagePadding: clamp(0.5rem, 2vw, 2rem);

// Reset and apply base styles.
html {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  // Supporting color-scheme is quite a lot of work.
  // color-scheme: light dark;
  // &.dark { color-scheme: dark; }
  // &.light { color-scheme: light; }
  * {
    box-sizing: border-box;
  }
}

html,
body,
ul,
ol,
li {
  padding: 0;
  margin: 0;
}

body {
  @include defaultColourVars;
  font-family: "Source Sans 3";
  background-color: var(--paper);
  color: var(--colour-text);
}

p {
  margin: 1rem 0;
  line-height: 1.4;
}

img {
  display: block;
  max-width: 100%;
}

h1,
h2,
h3,
h4 {
  font-family: AtkinsonHyperlegible;
  font-weight: bold;
  color: var(--colour-heading);
}

h1 {
  margin: 0 0 2rem;
  font-size: var(--font-size-huge);
}

h2 {
  margin: 2rem 0;
  font-size: clamp(2rem, 5.3vw, 3rem);
}

h3 {
  margin: 2rem 0 1rem;
  font-size: clamp(1.4rem, 4.5vw, 2.4rem);
}

h4 {
  margin: 2rem 0 1rem;
  font-size: clamp(1.2rem, 4vw, 2rem);
}

// Text styles in normal bulk text context
.body-text {
  font-size: var(--font-size-normal);

  ul {
    margin-left: 1.5rem;
    list-style: disc;
  }

  ol {
    margin-left: 2rem;
  }
}

// Shrink things when they're in a small space.
@container (width < 400px) {
  h1 {
    margin: 0 0 2rem;
    font-size: 2.2rem;
  }

  h2 {
    margin: 2rem 0;
    font-size: 2rem;
  }

  h3 {
    margin: 2rem 0;
    font-size: 1.4rem;
  }

  h4 {
    margin: 2rem 0;
    font-size: 1.2rem;
  }

  .body-text {
    font-size: var(--font-size-smaller);
    font-size: var(--font-size-smaller-cqw);
  }
}

// OpenDyslexic font
body.dyslexic-font {
  font-family: "OpenDyslexic";

  h1,
  h2,
  h3,
  h4 {
    font-family: "OpenDyslexic";
  }

  h1 {
    font-size: clamp(2rem, 4vw, 3rem);
  }
}

table {
  border: none;
  border-collapse: collapse;
  font-size: .875rem;
  font-size: var(--font-size-smaller);
  line-height: 1.4rem;
  margin: 2rem 0;
  width: 100%;
}

th,
td {
  padding: clamp(0.25rem, 0.6vw, 1rem) clamp(1rem, 1.1vw, 2rem);

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

th {
  border: none;
  border-bottom: solid 2px $orange2;
  text-align: left;
}

td {
  border: solid 1px #fff9ed; // almost invisible cream on white
  // border-bottom:solid 1px ;
}

tr:hover {
  background: #fdfaf6;
}


// @import 'dev-helpers';
@import 'page-header';

#content {
  min-height: 60vh;
}

.image-credits {
  font-size: var(--font-size-tiny);
  flex-wrap: wrap;
  display: flex;
  gap: 1rem 2rem;

  p {
    margin: 0;
  }

  .image-credit {
    opacity: 0.5;
    transition: 0.3s opacity;

    &:hover {
      opacity: 1;
    }

    flex: 0 0 auto;
    display: flex;
    gap: 0 1ch;

    img {
      display: block;
      flex: 0 0 auto;
    }

    &>div {
      flex: 1 1 auto;
    }
  }

}

// Footer
// #footer { }
.footer__contact {
  list-style: none;

  li {
    padding-left: 2rem;

    i {
      margin-left: -2rem;
      position: absolute;
    }
  }
}

// artfulrobot tagline v2 {{{
@keyframes ar-anim {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-0.3em);
    color: #fc8;
  }

  60% {
    transform: translateY(0.2em);
  }

  80% {
    transform: translateY(-0.15em);
  }

  0% {
    transform: translateY(0);
  }
}

a.ar-link {
  text-decoration: none;
}

a.ar-link:hover {
  border-bottom: solid 1px;
  color: white;
}

a.ar-link span {
  display: inline-block;
}

a.ar-link:hover span {
  animation-name: ar-anim;
  animation-duration: 0.6s;
  animation-timing-function: ease;
}

a.ar-link span.ar-a {
  animation-delay: 0s;
}

a.ar-link span.ar-r {
  animation-delay: 0.05s;
}

a.ar-link span.ar-t {
  animation-delay: 0.10s;
}

a.ar-link span.ar-f {
  animation-delay: 0.15s;
}

a.ar-link span.ar-u {
  animation-delay: 0.20s;
}

a.ar-link span.ar-l {
  animation-delay: 0.25s;
}

a.ar-link span.ar-r2 {
  animation-delay: 0.30s;
}

a.ar-link span.ar-o {
  animation-delay: 0.32s;
}

a.ar-link span.ar-b {
  animation-delay: 0.35s;
}

a.ar-link span.ar-o2 {
  animation-delay: 0.38s;
}

a.ar-link span.ar-t2 {
  animation-delay: 0.40s;
}

// }}}


// Generic styles
.align_center,
// TinyMCE adds this
.centre {
  text-align: center;
}

// Anchors: position so as to not get hidden by fixed headers
a.anchor {
  margin: -6rem;
  position: absolute;
}

// Default Links style
a,
a:visited {
  color: var(--colour-link);
  text-decoration: underline;
  text-decoration-color: var(--colour-link-decoration);
  text-decoration-thickness: var(--link-decoration-thickness);
}

a:hover,
a:active {
  color: var(--colour-link-hover);
  text-decoration-color: var(--colour-link-decoration);
}

a:visited {
  opacity: 0.8;
}

// White links see @mixin whiteLinks

a.button {
  @include button;
  display: inline-block;
  text-decoration: none;

  +a.button {
    // This is a button after a button
    margin-left: 2rem;
  }
}

// Adjust colours.
a.button.secondary {
  &:active {
    background: var(--dark-purple);
  }
}

p.large-text {
  // font-size: clamp(1.1rem, 1.9vw, 1.9rem);
  font-size: var(--font-size-larger);
}

// This sets the main content column width
.page-width {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1200px;
}

// This is slightly inside the main content column, so we don't butt up against the edge of the screen/window.
.page-padding {
  padding-left: $pagePadding;
  padding-right: $pagePadding;
}

// <div class="page-width page-padding">Some boring content</div>
// <div class=lurid-full-width-background><div class="page-width page-padding">Some boring content</div>
// <div class=page-width><img src=page-edge-image.jpg /><div class=page-padding>Text content</div>

.bg-white {
  background: white;
}


//
.ptn-triplet {
  h2 {
    font-size: clamp(2rem, 6vw, 3rem);
    text-align: center;
    margin-top: 0;
  }

  padding-top: 4rem;
  padding-bottom: 4rem;
}

// Fancy frame.
ul.triplet-cards-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;

  >li {
    flex: 1 0 280px;
    max-width: 21rem;
  }
}

.bg-arch-image {
  clip-path: polygon( // Note: the calc(100% + 1px) stuff is because firefox sometimes made a white line at certain screen sizes.
      0px 2.63rem, 13.45% 1rem, 13.92% 2.75rem, 14.32% 1rem, 41.93% 7px, 42.06% 2.32rem, 42.85% 2.06rem, 42.88% 0rem, 73.46% 0.06rem, 73.13% 2.75rem, 73.72% 3.25rem, 74.02% 26px, 100% 1rem, 100% calc(1px + 100%), 0px calc(1px + 100%));
  background-color: var(--cream);
}

// article
.card-wonkey-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%; // make the three grids the same height.

  .placard {
    // Adding transform means we need a z-index.
    transform: rotate(0.8deg) skew(-0.5deg);
    z-index: 0;
    transition: 0.3s transform;
  }

  &:hover .placard {
    transform: rotate(-0.8deg) skew(0.5deg);
  }

  .outline-outer {
    background-image: $stripeyGradient;
    padding: 0.5rem; // border width.
  }

  .stick {
    background: var(--dark-magenta);
    margin: 0 auto;
    width: 1.5rem;
    height: 3rem;
  }

  .text {
    padding: 0 0 1rem;
  }

  .link {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
  }
}

// @todo namespace this

%vertical-spread {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  hr {
    border: none;
    outline: none;
    flex: 10 0 0.5rem;
    background: none;
  }
}

%trim-vertical-margins {
  &>*:first-child {
    margin-top: 0;
  }

  &>*:last-child {
    margin-bottom: 0;
  }
}

em.colourful {
  position: relative;
  font-style: inherit;
  // background: white;
  // white-space: nowrap; // otherwise the next bit doesn't work.

  background: linear-gradient(-90deg, $purpleDeep, $magenta1, $orange1);
  color: transparent;
  background-clip: text;

}

@import 'part-common';
@import 'part-header-image';
@import 'part-text-and-image';
@import 'part-text-and-text';
@import 'part-bio';
@import 'part-history';
@import 'part-download-list';
@import 'uni-league';


.bg-placard {
  @include placard(alt);
  padding: 7%;

  &.hoverable:hover {
    background-position-x: 100%;
  }
}

.ptn-text-and-nav,
.ptn-text-and-text {

  // &>div {
  //   padding-top: 4rem;
  //   padding-bottom: 4rem;
  // }
  //
  .text {
    @extend %trim-vertical-margins;
    @extend %vertical-spread;
  }

  .box-padding {
    padding: clamp(0.5rem, 3.6vw, 3rem);
  }
}

.pnp-nav-container {
  // This then gives a problem when the div is taller than the viewport - you can't scroll down.
  // &>div {
  //   position: sticky;
  //   top: 2rem;
  // }
  background: $magentaFade;
  padding: 2vw $pagePadding;

  a {
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    font-weight: bold;
  }

  h2 {
    margin-top: 0;
    text-align: center;
  }

  li {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    line-height: 1.2;
  }

  ul.ancestor {
    margin-left: 0;
    list-style: none;
  }
}


// Special 'campaigner mode'
.campaigners-only {
  display: none;
}

body.campaigner-mode .campaigners-only {
  display: block;
}

body.campaigner-mode .not-for-campaigners {
  display: none;
}

// News/blog pages
.news-page {

  // Reduce h1 a bit; can be long.
  h1 {
    font-size: clamp(2.2rem, 5vw, 3.3rem)
  }

  .meta {
    color: var(--mid-grey);
    font-style: italic;
    text-align: center;
  }

  .tags {
    text-align: center;
  }

  .text {
    margin-left: auto;
    margin-right: auto;
    max-width: 60ch;
    line-height: 1.6;
    font-size: clamp(1rem, 2.3vw, 1.5rem);
  }

  .comments-wrapper {
    background: var(--cream);
    padding-top: 3rem;
    padding-bottom: 3rem;

    h3 {
      margin-top: 0;
      text-align: center;
    }
  }

  .text-wrapper ul,
  .text-wrapper ol {
    margin-left: 1.8em;
  }

  ul.CommentList {
    list-style: none;

    &>li {
      margin-bottom: 1rem;

    }
  }

  .CommentHeader {
    display: flex;
    justify-content: space-between;
    margin: 0;
    align-items: baseline;
  }

  .CommentCite {
    font-weight: bold;
    color: var(--dark-magenta);
    @include use-icon('\f27a'); // icon-commenting
  }

  .CommentCreated {
    color: var(--mid-grey);
  }

  .CommentText {
    background: white;
    box-shadow: 3px 3px 0px 0px var(--strong-orange);
    padding: 1px 1rem;
    flex: 0 1 auto;
    min-width: 23ch;
    color: var(--dark-grey);
  }
}

@media screen and (min-width: 768px) {
  .news-page {
    .header {
      display: grid;
      gap: 2rem;
      grid-template-columns: 1fr 1fr;

      h1 {
        grid-column: 1;
        grid-row: 1;
        margin: 0;
      }

      .image {
        grid-column: 2;
        grid-row: 1;
      }

      .meta {
        grid-column: 1 / 3;
        grid-row: 3;
      }

      .tags {
        grid-column: 1 / 3;
        grid-row: 2;
      }
    }

    ul.CommentList {
      &>li {
        display: flex;
        gap: 2rem;
      }
    }

    .CommentHeader {
      flex: 1 0 20ch;
      margin: 0;
      text-align: right;
      display: block; // remove the flex applied for mobile.

      &>* {
        display: block;
      }
    }

    .CommentText {
      padding: 0 1rem;
      flex: 10 0 20ch;
      min-width: 23ch;

      &::before {
        width: 0;
        height: 0;
        border-top: 0.5rem solid transparent;
        border-right: 1rem solid white;
        border-bottom: 0.5rem solid transparent;
        content: "";
        display: block;
        position: absolute;
        margin-left: -2rem;
        margin-top: 0;
      }
    }
  }
}

// CommentForm

#CommentForm {
  background: var(--bright-orange);
  // The default form uses <p> which brings along its own margins,
  // so we turn off padding here...
  padding: 0.5rem $pagePadding;
  max-width: 60ch;
  margin: 2rem auto;
}

// Notices
.pw-notices {
  padding: 1rem;
  position: absolute;
  right: 0;
  width: max(30ch, 30vw);
  background-color: var(--dark-magenta);
  clip-path: polygon(0 0, calc(100% - 0.5rem) 0, 100% calc(100% - 0.5rem), 0.5rem 100%);
  display: flex;

  &::before {
    content: '';
    background-color: var(--cream);
    width: 100%;
    height: 100%;
    position: absolute;
    margin: -1rem 0 0 -1rem;
    clip-path: polygon(0.2rem 0.2rem, calc(100% - 0.5rem) 0.4rem, calc(100% - 0.3rem) calc(100% - 0.5rem), 0.5rem calc(100% - 0.2rem));
  }


  .dismiss {
    flex: 0 0 auto;
    order: 2;
    display: block;
    height: 2rem;
    width: 2rem;
    text-align: center;
    line-height: 1;
    font-size: 2rem;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    text-decoration: none;
    z-index: 1;

    &:hover {
      border-color: var(--bright-magenta);
    }
  }

  &>p {
    flex: 1 1 auto;
    // z-index: 1;
    position: relative;
    z-index: 1;
    padding: 0.5rem 1rem;
    margin: 0;
    color: var(--dark-magenta);
  }
}

// Tags
ul.tags {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: var(--dark-magenta);
  @include use-icon('\e80d'); // tag
  flex-wrap: wrap;
  gap: 1rem;

  a {
    display: block;
    padding: 0 0.5em;
    font-size: 0.875rem;
    background: var(--dark-magenta);
    color: white;
    border-radius: 2px;
    text-decoration: none;

    &:hover,
    &:active {
      background: var(--bright-magenta);
    }
  }
}

// Teasers list is a grid of rows
ul.teasers-list {
  list-style: none;

  // The whole thing is a link
  a {
    text-decoration: none;
  }

  article {
    display: grid;
    grid-template-columns: 20vw 1fr;
    grid-template-rows: auto 1fr;
    gap: 0 2rem;
    margin-bottom: 2rem;

    &:hover {
      background: var(--cream);
    }
  }

  h1 {
    font-size: clamp(1.1rem, 1.9vw, 1.9rem);
    grid-column: 2;
    margin: 0;
  }

  .meta {
    grid-column: 2;
  }

  .image {
    grid-row: 1;
  }

}

@import 'cards-list';
@import 'part-campaign-latest';

// Pager
.MarkupPagerNav {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  a {
    display: inline-block;
    padding: 0.25rem 1rem;
    text-decoration: none;

    &:active,
    &:hover {
      background-color: var(--cream);
    }
  }

  li.MarkupPagerNavOn a {
    background-color: var(--bright-magenta);
    color: white;
  }
}

// Mini admin sidebar
#admin-links {
  display: none;
}

@media screen and (min-width: 768px) {

  // Space for #admin-links
  body.logged-in {
    margin-left: 2rem;
  }

  #admin-links {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 2rem;
    bottom: 0;
    background: #354b60;

    a {
      display: block;
      padding: 0.25rem 0rem;
      width: 2rem;
      text-align: center;
      color: white;
      text-decoration: none;

      &:hover {
        background: var(--bright-magenta);
      }
    }
  }
}


// Blog search form
form.news-search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem;
  background: var(--cream);
  align-items: flex-end;

  .needle,
  .tag,
  .submit {
    flex: 1 0 23ch;

    input,
    button {
      width: 100%;
    }
  }
}

// Main search form
form.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: flex-end;

  .needle {
    flex: 1 0 23ch;

    input {
      width: 100%;
    }
  }
}

// Newsy/social links
.news-more {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
  gap: 1rem;

  &>div {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  a {
    text-decoration: none;
  }

  &>a {
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;

    &:hover {
      background: $cream;
    }
  }

  .news-index-link {
    flex: 1 0 auto;

    a {
      padding: 0.25rem 0;
      font-weight: bold;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Social share links
.social-share-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  list-style: none;

  a {
    text-decoration: none;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;

    &:hover {
      background: $cream;
    }
  }
}

// Embedded tweets
article.textfomatter-tweet-embed {
  background: var(--dark-magenta);
  color: white;
  border-radius: 8px;
  margin-bottom: 2rem;
  margin-top: 1rem;
  @include whiteLinks;

  h1 {
    font-size: var(--font-size-normal);
    color: white;
    padding: 1rem 1rem 0;
    margin: 0;
  }

  blockquote {
    padding: 1rem 1rem;
    margin: 0;

    p:last-child {
      margin-bottom: 0;
    }

    p:first-child {
      margin-top: 0;
    }
  }
}

// Time windowed parts.
// .timewindow-preview {}

.timewindow-note {
  border-radius: 1em 1em 0 0;
  font-size: var(--font-size-tiny);
  font-weight: bold;
  text-align: center;
  background: #aaa;
  color: black;
}

.timewindow-content {
  box-shadow: 0 0 1rem 3px #aaa inset;
}

.timewindow-content.hidden {
  opacity: 0.5;
}


#scroll-to-top-target {
  position: absolute;
  top: calc(5rem);
  width: 1px;
  height: 1px;
}

#scroll-to-top {
  position: fixed;
  bottom: 1rem;
  right: 0.2em;
  border-radius: 3rem;
  text-align: center;
  display: block;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.6rem;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s opacity, 0.3s transform;
  background: var(--bright-magenta);
  transform: translateY(4rem);
  color: white;

  &:hover,
  &:focus {
    background: var(--dark-magenta);
    color: white;
  }
}

body.scrolled #scroll-to-top {
  opacity: 1;
  pointer-events: all;
  transform: none;
}

// Hack: if you put a hark survey in a purple colourway the text on white is unreadable.
.hark-answer {
  color: #222;
}

@import 'inlaypay';
