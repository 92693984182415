.part-bio {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 25% 1fr;
  grid-template-rows: auto 1fr;
  gap: 0 1rem;

  h2 {
    grid-column: 2;
    grid-row: 1;
    font-size: var(--font-size-larger);
    margin: 0 0 1rem;
    line-height:1; // Atkinson Hyperlegible seems fine like this.

  }
  .name {
    display: block;
  }
  .role {
    color: var(--dark-magenta);
    font-size: var(--font-size-normal);
  }

  .image {
    grid-column: 1;
    grid-row: 1;
    .bg-stripey {
      aspect-ratio:1;
    }
    &.clip-path-1>* {
      clip-path: polygon(10.75% 4.39%, 95.24% 1.22%, 97.54% 79%, 4.58% 83.15%);
    }
    &.clip-path-2>* {
      clip-path: polygon(6.27% 7.91%, 90.31% 2.63%, 88.74% 81.82%, 9.51% 85.26%);
    }
    &.clip-path-3>* {
      clip-path: polygon(5.75% 0.16%, 95.24% 1.22%, 97.54% 79%, 8.81% 82.45%);
    }
    &.clip-path-4>* {
      clip-path: polygon(13.67% 1.22%, 90.31% 4.74%, 94.73% 85.34%, 4.93% 86.32%);
    }
  }

  .bio {
    grid-column: 1/3;
    grid-row:2;
    font-size: var(--font-size-normal);
    p:first-child { margin-top:0; }
  }
}

@media screen and (min-width: 768px) {
  .part-bio {
    grid-template-columns: 25% 1fr;

    h2 {
      grid-column: 2;
      grid-row: 1;
    }

    .image {
      grid-row: 1/3;
    }
    .bio {
      grid-column: 2;
      grid-row:2;
    }
  }
}
