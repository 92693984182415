.ptn-text-and-image {

  &>div {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .image img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .text { @extend %vertical-spread; }
  .text { @extend %trim-vertical-margins; }
  .text { padding-bottom: 2rem; }

}
@media screen and (min-width: 768px) {
.ptn-text-and-image {

  .text { padding-bottom: 0; }

  &>div {
    display: grid;
    gap: 4rem;
  }

  &.cols-1-1>div {
    grid-template-columns: 1fr 1fr;
  }
  &.cols-2-1>div {
    grid-template-columns: 2fr 1fr;
  }
  &.cols-1-2>div {
    grid-template-columns: 1fr 2fr;
  }

}
}
