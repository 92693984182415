.part-campaign-latest {

  .campaign {
    margin-bottom: 2rem;
    padding: 1rem; // todo
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    background: $cream;
  }

  .campaign-header {
    flex: 0 1 20rem;

    h2 {
      margin: 0 0 2rem;
      img { width: 100%; height: auto; display: block; }
    }
    .links {
      padding-left: 1.5rem;
    }
  }

}
ul.campaign-latest {
  list-style: none;
  flex: 1 0 15rem;

  li { margin-bottom: 2rem;
    &:last-child { margin-bottom: 0; }
  }
  a {
    text-decoration: none;
    display: block;

    &:hover {
      background-color: white;
    }
  }


}
article.campaign-latest {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .image {
    order: 1;
    flex: 0 0 7rem;
  }
  .meta {
    order: 2;
    flex: 1 0 9rem;
    // padding: 1rem 1rem;
  }

  h1 {
    font-size: var(--font-size-normal);
    margin: 0;
  }
  &.petition h1 { color: var(--dark-magenta); }

}
