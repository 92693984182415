// This contains shared patterns.

// Implement columns on descendent divs to .cols
// .cols-x-y
// [h2.span-cols]
//   div
//   div
@media screen and (min-width: 768px) {
  .cols-1-1,
  .cols-2-1,
  .cols-1-2 {
    display: grid;
    gap: 4rem;

    &>.span-cols {
      grid-column: 1 / 3;
    }

    &>div {
      container-type: inline-size;
    }
  }

  .cols-1-1 {
    grid-template-columns: 1fr 1fr;
  }
  .cols-2-1 {
    grid-template-columns: 2fr 1fr;
  }
  .cols-1-2 {
    grid-template-columns: 1fr 2fr;
  }
}
.ai-center {
  align-items: center;
}

// Colour utility classes
.bg-white          { background: white;     }
.bg-dark-grey      { background: var(--dark-grey); }
.bg-mid-grey       { background: var(--mid-grey); }
.bg-dark-purple    { background: var(--dark-purple); }
.bg-bright-purple  { background: var(--bright-purple); }
.bg-dark-magenta   { background: var(--dark-magenta); }
.bg-bright-magenta { background: var(--bright-magenta); }
.bg-strong-orange  { background: var(--strong-orange); }
.bg-bright-orange  { background: var(--bright-orange); }
.bg-cream          { background: var(--cream); }
.bg-subtle-pinkish { background: var(--subtle-pinkish); }
.bg-stripey        { background: $stripeyGradient; }

.dark-grey      { color: var(--dark-grey); }
.mid-grey       { color: var(--mid-grey); }
.dark-purple    { color: var(--dark-purple); }
.bright-purple  { color: var(--bright-purple); }
.dark-magenta   { color: var(--dark-magenta); }
.bright-magenta { color: var(--bright-magenta); }
.strong-orange  { color: var(--strong-orange); }
.bright-orange  { color: var(--bright-orange); }
.cream          { color: var(--cream); }

// What should we do with text, headings, links depending on background colour?

.colourway-white,
.colourway-subtle-pinkish,
.colourway-cream {
  // This resets the defaults, e.g. if something else above this has done a different colourway and we need to reset.
  @include defaultColourVars;
  // We have to specifically set the text colour.
  color: var(--colour-text);
}

.colourway-dark-purple,
.colourway-dark-magenta,
.colourway-dark-grey,
.colourway-mid-grey,
.colourway-bright-purple {
  --colour-text: white;
  --colour-heading: var(--cream);
  --colour-secondary-button-text: var(--cream);
  color: var(--colour-text);
  @include whiteLinks;
}
.colourway-bright-magenta {
  --colour-text: white;
  --colour-heading: white;
  --colour-secondary-button-text: var(--cream);
  color: var(--colour-text);
  @include whiteLinks;
}
.colourway-strong-orange {
  --colour-heading: var(--dark-purple);
}

// Padding-vertical
.pv1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
.pv2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.pv3 { padding-top: 1rem; padding-bottom: 1rem; }
.pv4 { padding-top: 2rem; padding-bottom: 2rem; }
.pad-top-0 { padding-top:0; }
.pad-top-1 { padding-top:0.25rem; }
.pad-top-2 { padding-top:0.5rem; }
.pad-top-3 { padding-top:2vw; }
.pad-top-4 { padding-top:3vw; }
.pad-top-5 { padding-top:4vw; }
.pad-bottom-0 { padding-bottom:0; }
.pad-bottom-1 { padding-bottom:0.25rem; }
.pad-bottom-2 { padding-bottom:0.5rem; }
.pad-bottom-3 { padding-bottom:2vw; }
.pad-bottom-4 { padding-bottom:3vw; }
.pad-bottom-5 { padding-bottom:4vw; }

.uppercase { text-transform: uppercase; }

.whiteLinks {
  @include whiteLinks;
}

.max-width-600 {
  max-width: 600px;
}
.box-shape-diamond {
  clip-path: polygon( 0 1vw, 50% 0, 100% 1vw, 100% calc(100% - 1vw), 50% 100%, 0 calc(100% - 1vw));
}
.box-shape-placard-1 {
  clip-path: polygon( 0 1vw, 100% 0, 100% 100%, 0 calc(100% - 1vw));
}
.box-shape-placard-2 {
  clip-path: polygon( 0 0, 100% 1vw, 100% calc(100% - 1vw), 0 100% );
}
.box-shape-placard-3 {
  clip-path: polygon( 0 0, 100% 1vw, 100% 100%, 0 calc(100% - 1vw) );

}

.pull-out {
  position: relative;
  padding: 1.5rem;
  margin: 2rem auto;
  max-width: 45em;
  font-size: var(--font-size-larger);
  &::before {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    z-index: -1;
    content: '';
    display: block;
    box-sizing: border-box;
    border: 0.5em solid transparent;
    border-image: linear-gradient(-135deg, #303030 14.3%, #3f1549 14.3%, #3f1549 28.6%, #662a73 28.6%, #662a73 42.9%, #bd1560 42.9%, #bd1560 57.1%, #d90d59 57.1%, #d90d59 71.4%, #f67519 71.4%, #f67519 85.7%, #ff9804 85.7%);
    border-image-slice: 15;
    background: white;
    transform: rotate(-1deg);
    outline: 1px solid transparent;
  }
}

// screen reader only from https://css-tricks.com/inclusively-hidden/
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// @see .body-text (needs to be in main due to container query)


// in part-animated-statistic
.body-text ul.stats-list {
  list-style: none;
  padding: 0 1rem;
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: flex-start;
  color: var(--section-colour, var(--bright-purple));

  &>li {
    flex: 1 0 200px;
    text-align: center;
    padding: 0;
    margin:0;
    a { text-decoration: none; color: inherit; transition: 0.2s transform; display: block; }
    a:hover, a:focus { transform: scale(1.1); color: var(--bright-magenta); }
  }

  strong {
    line-height: 1;
    font-weight: bold;
    font-style: normal;
    font-size: var(--font-size-huge);
  }
}
