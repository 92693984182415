$greyMid            : #636363;

$softBlack          : #222222;
$greyDark           : #303030;
$purpleDark         : #3f1549;
$purpleDeep         : #591068;
$purpleMeh          : #662a73;
$creamWithPurpleHint: #f5dadf;
$cream              : #ffe6c1;
$paper              : #fcf8f3;
$magentaFade        : #f9edf1;
$magenta1           : #bd1560;
$magenta2           : #d90d59;
// $orange1         : #f67519;
$orange1            : #ef6706; // WCAG AA for large text on cream
$orange2            : #ff9804;


$red           : #aa1414;
$redTint       : #e73232;
$green         : #3caa14;

$stripeyGradient: linear-gradient(
      135deg,
      $greyDark 14.3%,
      $purpleDark  14.3%,
      $purpleDark  28.6%,
      $purpleMeh  28.6%,
      $purpleMeh  42.9%,
      $magenta1 42.9%,
      $magenta1 57.1%,
      $magenta2 57.1%,
      $magenta2 71.4%,
      $orange1  71.4%,
      $orange1  85.7%,
      $orange2  85.7%
      );

