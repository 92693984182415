// This file contains all the styles for the header including menus.
//

#page-header {
  position:relative;
  z-index: 2; // above .topper which follows (and has zindex 1)
}

body.mobile-menu-open {
  position:fixed;
  overflow:hidden;
}

@keyframes mobile-menu {
  0% { transform: translateY(100vh); opacity: 0; }
  1% {
    transform: scale(0.9) translateY(10vh);
    opacity: 0;
  }
  100% { transform: none; opacity: 1; }
}
// Rules shared between mobile and desktop
#header-nav {

  background-color: var(--dark-purple); // gets overridden by sections/toppers adding classes to body.
  display: flex;
  justify-content: space-between;
  padding: $pagePadding;
  gap: 1rem;

  ul, li { margin:0; padding:0; }
  ul { list-style: none; }

  a:not(#logo-home-link), span.placeholder, label.item {
    display: block;
    text-decoration: none;
    padding: 0.5rem 1.5rem;
  }
  a.text, span.placeholder {
  }
}


// Rules for mobile only; to save having to overwrite in the desktop mediaquery
@media screen and (max-width: 767px) {
// Render the menu off page.
#header-menu {
  background-image: url('/site/templates/images/menu-top-decoration.svg');
  background-position: top left;
  background-size: cover;

  transform-origin:right;
  position: fixed;
  z-index: 1;
  padding-top: 1rem;
  top: 2.6rem;
  width: 100%;
  margin-left: -0.5rem; // pull back from the 0.5 padding
  bottom:0;
  transform: rotate(-5deg) translateY(100vh);
  transition: 0.3s transform, 0.2s opacity;
  opacity:0;
}

#header-nav {
    flex-wrap:wrap;

    ul.menu-level-0 {
      padding: 0 1rem;
      overflow: auto;
      height: 100%;
    }

    li {
      margin-top: 0.5rem;
      display: grid;
      gap: 0 0.5rem;
      grid-template-columns: 1fr 2.5rem;

      &>ul {
        grid-column: 1/3;
      }
      // Show chilren when toggled.
      &.show-menu>ul { display: block; }
    }

    // Button
    .submenu-toggle {
      border: none;
      background: transparent;
      text-align: center;
      height: 2.5rem;
      aspect-ratio: 1;
      line-height:1;
      font-size: 1.8rem;
      color: var(--bright-purple);
    }

    li.depth-1 .submenu-toggle {
      color: white;
    }

    // Hidden until button pressed.
    ul.menu-level-1, ul.menu-level-2, ul.menu-level3 {
      display: none;
    }

    &[aria-pressed="true"] #header-menu {
      animation: 0.1s mobile-menu forwards ease-in;
    }

    // Colours for the menu items is really hard because there's
    // - *mobile*/desktop
    // - depth/levels
    // - trail/self/other
    // - hover states.

    ul.menu-level-1 {
      background-color: var(--dark-magenta);
    }
    .item.depth-0.other { background-color:var(--bright-purple); color: white; }
    .item.depth-0.trail { background-color:$magenta2; color: white; }
    li.depth-1.other {  background-color:transparent; color: white;  }
    li.depth-1.trail {  background-color:var(--bright-magenta); color: white;  }
    a, a:visited { color: white; position: relative; }
    a:hover, a:active { color: white; background-color: rgba(0, 0, 0, 0.1); }

    li.depth-1 {
      $bulletSize: 5px;
      a.trail::before {
        content: '';
        width: $bulletSize;
        height: $bulletSize;
        position: absolute;
        margin-left: -$bulletSize*2.5;
        // margin-top: 0.5rem;
        top: calc(50% - $bulletSize/2);
        background-color: white;
        border-radius: $bulletSize;
      }
    }

    a.depth-2 {
      margin: 0.25rem 1rem 0.25rem 1rem;
    }
}
}

#logo-home-link {

  flex:0 0 190px;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

#menu-toggle {
  flex: 0 0 auto;
  border:none;
  background: var(--dark-purple);
  color:white;
  padding: 0.5rem 1rem;
  align-self: center;
  border-radius: 1rem;
  cursor: pointer;

  font-family: AtkinsonHyperlegible;
  font-weight: bold;

  &:hover { background: var(--bright-purple); }
  &:active { background: var(--bright-purple); }
}

#breadcrumbs { display: none; }

// Desktops
@media screen and (min-width: 768px) {

  #page-header {
    // height: min(13vw, 14rem);
    // background-size: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  #logo-home-link {
    flex:0 0 clamp(190px, 19.4vw, 330px);
  }

  // Remove buttons that are just for mobile.
  .submenu-toggle,
  #menu-toggle { display: none; }

  #header-nav {
    line-height: 1.2;

    .logo img {
      height: auto;
      width: 100%;
      display: block;
    }

    // Main menu, always visible on desktop
    ul.menu-level-0 {
      flex: 0 0 auto;
      display: flex;
      gap: 1rem;
    }
    li.depth-0 {
      margin-top: 0;
      position: relative;
      display: block;
      padding-left: 0.5rem;
      text-decoration: none;

      // When we're hovered...
      &:hover {
        &>.item {
          background-color: var(--bright-purple);
        }

        // ...reveal popup menu
        ul.menu-level-1 {
          transform: none;
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    // popup menus
    ul.menu-level-1 {
      background-color: var(--dark-magenta);
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      transform: rotateX(21deg);
      transform-origin: top;
      transition: opacity .3s ease-out,transform .15s ease-out;
      width: 35ch;
      z-index: 2;
    }

    // Colours for the menu items is really hard because there's
    // - mobile/ *desktop*
    // - depth/levels
    // - trail/self/other
    // - hover states.
    .item.depth-0.other { background-color:rgba(0, 0, 0, 0.2); color: white; }
    .item.depth-0.trail { background-color:$magenta2; color: white; }
    li.depth-1.other {  background-color:transparent; color: white;  }
    li.depth-1.trail {  background-color:var(--bright-magenta); color: white;  }
    li.depth-1 {
      a, a:visited { color: white; position: relative; }
      a:hover, a:active { color: white; background-color: rgba(0, 0, 0, 0.1); }
    }
    li.depth-1 {
      $bulletSize: 5px;
      a.trail::before {
        content: '';
        width: $bulletSize;
        height: $bulletSize;
        position: absolute;
        margin-left: -$bulletSize*2.5;
        // margin-top: 0.5rem;
        top: calc(50% - $bulletSize/2);
        background-color: white;
        border-radius: $bulletSize;
      }
    }

    a.depth-2 {
      margin: 0.25rem 1rem 0.25rem 1rem;
    }

  }

  #breadcrumbs {
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .separator {
      background-color: $cream;
      width: 0.4ch;
      transform: skewX(-10deg);
      height: 1.4em;
      margin: 0 1ch;
      vertical-align: middle;
      display: inline-block;
    }
    .item, .item:visited {
      color: $orange1;
      text-decoration: none;
    }
    a.item:hover {
      text-decoration: underline;
    }
  }
}

// Toppers.
.topper {
  position: relative;
  z-index: 1; // to overlap an image following.
}
// divest-borders, fossil-free and fossil-free-careers have the same topper except for some colours.
// » use .campaign-topper class.
body.divest-borders {
  --section-colour: var(--dark-magenta);
  #header-nav { background-color: var(--dark-magenta); }
  .topper {
    --topper-bg: var(--dark-magenta);
    --placard-bg: white;
    .topper-home {
      padding: 0.5rem 1rem;
    }
  }
}
body.fossil-free {
   --section-colour: var(--strong-orange);
  #header-nav { background-color: var(--dark-grey); }
  .topper {
    --topper-bg: var(--dark-grey);
    --placard-bg: #f79123;
  }
}
body.fossil-free-careers {
  --section-colour: var(--dark-grey);
  #header-nav {
    background-color: var(--bright-orange);
  }
  .topper {
    --topper-bg: var(--bright-orange);
    --placard-bg: #232627;
  }
}

.topper.campaign-topper {
  padding: 0.3vw 0 1vw 2vw; // 0.3vw at top allows for rotation of logo
  display: flex;
  gap: 0 2rem;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  top:0;

  &::before {
    position: absolute;
    content: '';
    z-index: 0;
    background-color: var(--topper-bg);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2vw), 0 100%);
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  // This is the logo-link to the section home
  .topper-home {
    position: relative;
    background-color: var(--placard-bg);
    width: clamp(142px, 19.4vw, 330px);
    height: clamp(53px, 10vw, 100px);
    transform: rotate(-1.1deg);

    display: block;
    img {
      display: block;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    &::after {
      width: 1vw;
      height: 1.3vw; // enough to stick out
      left: calc(50% - 0.5vw);
      top: 100%;
      margin-top: -1px;
      background-color: var(--placard-bg);
      position: absolute;
      content: '';
    }
  }

  .topper-menu {
    position: relative;

    &>button {
      background-color: transparent;
      border: none;
      border-radius: 0;
      color: white;
      appearance: none; // disable e.g. Safari stuff.
      margin: 0;
      padding: 0.5rem 2rem;
    }
  }
  .topper-popper {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    background-color: var(--dark-magenta);
    transform: rotateX(21deg);
    transform-origin: top;
    transition: opacity .3s ease-out,transform .15s ease-out;
    right: 0;
    top: 2rem;
    width: 29ch;

    &>ul {
      margin:0;
      padding:0;
      list-style:none;
    }
    li {
      padding: 0;
      margin: 0;
    }
    a, .self>span {
      padding: 0.5rem 2rem;
      display: block;
      color: white;
    }
    .self>span {
      font-weight: bold;
    }
    a {
      text-decoration: none;
      &:hover {
        background-color: var(--bright-magenta);
      }
    }
  }

  // Open on click.
  .topper-menu:hover,
  .topper-menu.open {
    &>button {
      background-color: var(--bright-purple);
    }
    .topper-popper {
      opacity: 1;
      pointer-events: all;
      transform: none;
    }
  }
}
