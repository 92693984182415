.ptn-history {
  ul.history-list {
    margin-left: 0;
    list-style: none;
    &>li {

      display: grid;
      grid-template-columns: 20ch 1fr;

      &:nth-child(even) {
        background: $cream;
      }
      &>h2 {
        // font-size: var(--font-size-larger);
        margin:0;
        grid-column: 2;
        grid-row: 1;
        border-left: solid 0.5rem var(--bright-purple);
        padding-left: 1rem;
        padding-top:1rem;
      }
    }
  }
  .details {
    grid-column: 2;
    grid-row: 2;
    border-left: solid 0.5rem var(--bright-purple);
    padding: 1rem 1rem 2rem 1rem;
    &>img {
      max-width: 40%;
      float:right;
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
    .text img { max-width: 100%; height: auto; }
  }
  .circa {
    grid-column: 1;
    grid-row: 1/3;
    div {
      position: sticky;
      top: 5rem;
      padding: 0 1rem 1rem 1rem;
      font-weight: bold;
      color: var(--bright-magenta);

      &::before {
        position: absolute;
        content: '';
        width:1rem;
        height: 0.5rem;
        border-radius: 0.5rem 0 0 0.5rem;
        right:0;
        top: 0.5rem;
        background-color: var(--bright-purple);
      }
    }
  }
}
@media screen and (min-width: 768px) {

}
@media screen and (max-width: 767px) {

  .ptn-history {
    ul.history-list {
      &>li {
        grid-template-columns: 1fr;
        &>h2 {
          grid-row: 2;
          grid-column: 1;
        }
      }
    }
    .details {
      grid-row: 3;
      grid-column: 1;
    }
    .circa {
      grid-row: 1;
      border-left: solid 0.5rem var(--bright-purple);
      div {
        padding-left: 1rem;
        &::before {
          border-radius: 0 0.5rem 0.5rem 0;
          left:0;
          right: unset;
          top: 0.5rem;
        }
      }
    }
  }
}
