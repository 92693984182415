.ff-scorecard {
  background-color:var(--dark-grey);
  border-radius:5px;
  color:white;
  padding:2rem;
  margin: 2rem 0 4rem;

  h1 {
    margin:0 0 1rem;
    color: var(--bright-orange);
    font-size: var(--font-size-larger);
  }
  h2 {
    color: var(--paper);
    font-size: var(--font-size-larger);
    margin:2rem 0 0;
    text-transform:none;
  }
  p {
    margin-bottom: 1rem;
  }
  .detail {
    opacity:0.7;
    font-size: var(--font-size-smaller);
    line-height:1;
  }
}
.ff-scorecard__divestment-details {
  font-size: var(--font-size-smaller);
}
// Used by SVG.
.ff-scorecard__number {
  font-size: var(--font-size-larger);
}
// Badge {{{
.ff-scorecard__badge {
  float:right;
  width:15em;
  padding:1rem;
  margin-left:2rem;
  margin-top:-2rem;
  border-radius: 0 0 3px 3px;
  text-align:center;
  box-shadow: 2px 2px 20px -5px rgba(0,0,0,0.6);
  border: solid 1px rgba(0,0,0,0.1);
  border-top: none;
  font-family: AtkinsonHyperlegible;
  font-weight: bold;
}
.ff-scorecard__badge--unknown {
  display:none;
}
.ff-scorecard__badge--none {
  background-color: var(--red);
  color:white;
}
.ff-scorecard__badge--partial {
  background-color: var(--strong-orange);
  color: var(--dark-grey);
}
.ff-scorecard__badge--full {
  background-color: white;
  color: var(--green);
  .ff-scorecard__badge-value:before {
  }
}
.ff-scorecard__badge-prefix {
  font-size: var(--font-size-larger);
  display:block;
}
.ff-scorecard__badge-value {
  font-size:58px;
  display:block;
  text-transform:uppercase;
}
.ff-scorecard__badge-details {
  font-size: var(--font-size-smaller);
}
// }}}
div.pie svg {
  background: var(--paper);
  circle {
    fill: var(--paper);
  }
}
.ff-scorecard__row {
  display:flex;
  flex-wrap:wrap;
  align-items: flex-start;
  margin:0 -1rem;
}
.ff-scorecard__inv-total {
  flex:0 0 auto;
  font-size:3rem;
  font-family: 'AtkinsonHyperlegible';
  font-weight: bold;
  text-align:right;
  padding:0 1rem;
  color: var(--red);
}
.ff-scorecard__inv-diff {
  font-size:1rem;
}
.ff-scorecard__inv-details {
  flex:8 1 10rem;
  padding-right:1rem;
  ul {
    margin-top:0;
    margin-bottom:0;
    padding-left:0;
  }
  li {
    // padding-left:0;
    &:before {
      color: var(--strong-orange);
      font-size: 12px;
      line-height:22px;
    }
  }
}
.ff-scorecard__endow-pie {
  flex:0 0 76px;
}
.ff-scorecard__endow-text {
  flex:1 1 10rem;
}
.ff-scorecard__legend-item {
  padding-left:1.8rem;
  span.ff-scorecard__legend-blob {
    position:absolute;
    margin-left:-1.8rem;
    margin-top:3px;
    display:block;
    width:1em;
    height:1em;
  }
}

.ff-scorecard__donations-value {
  flex:0 0 auto;
  font-size:3rem;
  padding:0 1rem;
  color: var(--red);
}
.ff-scorecard__donations-text {
  flex:1 1 10em;
}
.ff-scorecard__descr-col {
  flex:1 0 10rem;
  padding:0 1rem;
}
.ff-scorecard__small-col,
.ff-scorecard__misc-col {
  flex:1 0 10rem;
  padding:0 1rem;
}
