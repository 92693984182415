@import 'mixins';
:root {
  // Font sizes
  --font-size-huge:        clamp(2.2rem,   5vw,    4rem);
  --font-size-larger:      clamp(1.2rem,   4vw,    2rem);
  --font-size-normal:      clamp(1rem,     1.6vw,  1.375rem);
  --font-size-smaller:     clamp(0.875rem, 1.2vw,  1.2rem);
  // cqw: Container query width
  --font-size-smaller-cqw: clamp(0.875rem, 5.5cqw, 1.2rem);
  --font-size-tiny:        clamp(0.825rem, 1vw,    1rem);

  // Colours
  --soft-black    : #{$softBlack};
  --mid-grey      : #{$greyMid};
  --dark-grey     : #{$greyDark};
  --dark-purple   : #{$purpleDark};
  --bright-purple : #{$purpleDeep};
  --meh-purple    : #{$purpleMeh};
  --dark-magenta  : #{$magenta1};
  --bright-magenta: #{$magenta2};
  --strong-orange : #{$orange1};
  --bright-orange : #{$orange2};
  --cream         : #{$cream};
  --paper         : #{$paper};
  --subtle-pinkish: #{$creamWithPurpleHint};

  --placard-bg-default: #{$creamWithPurpleHint};
  --placard-bg-hover: #{$cream};

  --red : #{$red};
  --red-tint : #{$redTint};
  --green : #{$green};

  // Specific stuff
  --grpet-form-bg: var(--cream);
}


body.high-contrast {
  --placard-bg-default: #{$paper};
  --colour-heading: var(--dark-purple);
  --colour-text: black;
  --paper: white;
}
