// main league app
.inlayul-container {
  font-size: var(--font-size-smaller);
  .filters {
    align-items: center;
    background-color: var(--placard-bg-default);
    display: flex;
    flex-wrap: wrap;
    padding: .5rem 1rem;
  }
  .filter-text {
    flex: 1 0 20ch;
    padding: .5rem 1rem;
  }
  .filter-container {
    flex: 0 0 26ch;
    padding: .5rem 1rem;
  }
}

// uni profile pages
article.ul-scorecard-container {

  ul.ul-barchart {
    // Overrides for .body-text styles
    list-style: none;
    margin-left: 0;
    font-size: var(--font-size-smaller);
  }

  // @todo move this into the inlay src?
  .ul-barchart__text { padding-left: 8ch; text-indent: -8ch;  }
  .ul-barchart__text > strong { padding-right: 1ch; width: 7ch;}
  .ul-barchart__bar-domain { box-shadow: none; }
}


