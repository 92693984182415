@font-face {
  font-family: "AtkinsonHyperlegible";
  font-weight: bold;
  src: local("Atkinson Hyperlegible"),
    url("/site/templates/fontAH/Atkinson-Hyperlegible-Bold-102a.woff2"),
    url("/site/templates/fontAH/fontAH/Atkinson-Hyperlegible-Bold-102.woff"),
    url("/site/templates/fontAH/Atkinson-Hyperlegible-Bold-102.ttf"),
    url("/site/templates/fontAH/Atkinson-Hyperlegible-Bold-102.svg"),
    url("/site/templates/fontAH/Atkinson-Hyperlegible-Bold-102.eot");
}
@font-face {
  font-family: "AtkinsonHyperlegible";
  font-weight: bold;
  font-style: italic;
  src: local("Atkinson Hyperlegible"),
    url("/site/templates/fontAH/Atkinson-Hyperlegible-BoldItalic-102a.woff2"),
    url("/site/templates/fontAH/Atkinson-Hyperlegible-BoldItalic-102.woff"),
    url("/site/templates/fontAH/Atkinson-Hyperlegible-BoldItalic-102.ttf"),
    url("/site/templates/fontAH/Atkinson-Hyperlegible-BoldItalic-102.svg"),
    url("/site/templates/fontAH/Atkinson-Hyperlegible-BoldItalic-102.eot");
}

@font-face {
  font-family: "OpenDyslexic";
  font-weight: normal;
  src: local("OpenDyslexic"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Regular.woff2"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Regular.woff"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Regular.otf"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Regular.eot");
}
@font-face {
  font-family: "OpenDyslexic";
  font-weight: bold;
  src: local("OpenDyslexic"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Bold.woff2"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Bold.woff"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Bold.otf"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Bold.eot");
}
@font-face {
  font-family: "OpenDyslexic";
  font-weight: normal;
  font-style: italic;
  src: local("OpenDyslexic"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Italic.woff2"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Italic.woff"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Italic.otf"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Italic.eot");
}
@font-face {
  font-family: "OpenDyslexic";
  font-weight: bold;
  font-style: italic;
  src: local("OpenDyslexic"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Bold-Italic.woff2"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Bold-Italic.woff"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Bold-Italic.otf"),
    url("/site/templates/fontOpenDyslexic/OpenDyslexic-Bold-Italic.eot");
}

