.ptn-item {
  display: grid;
  &>* {
    grid-column: 1;
    grid-row: 1;
  }

  .box-padding {
    padding: clamp(0.5rem, 3.6vw, 3rem);

    h2:first-child, h3:first-child {
      margin-top: 0;
    }
  }
}
