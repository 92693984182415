// This is the 'latest news' layout.

ul.cards-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;
  gap: 2rem;

  // Note bg-placard may be applied here.
  &>li {
    flex: 1 0 220px;
    max-width: 400px;

    // The whole article is wrapped in an <a>
    a {
      height: 100%;
      display: block;
      text-decoration: none;
      @include placard(flat-top, yes);
    }
  }

  article {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  div.image {
    order: 1;
    flex: 0 0 5rem;
  }
  article.bg-placard {
    padding: 0; // Remove default bg-placard padding.
  }

  .meta {
    order: 2;
    flex: 1 0 auto;
    // font-size: clamp(1.1rem, 1.9vw, 1.9rem);
    padding: 0 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  h1 {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    margin:0;
    padding: 1rem 0;
  }

}
@media screen and (min-width: 768px) {

  // Big variant - only applies outside of mobile.
  ul.cards-list >li.big {
    max-width: none;
    flex-basis: 100%;
    background: var(--dark-purple);
    padding: 1rem;
    &:hover {
      background: var(--bright-purple);
    }

    a {
      clip-path: none;
      background: none;
    }

    article {
      display: grid; // not flex
      grid-template-columns: 2fr 1fr;
      gap: 1rem;
    }

    .meta {
      @include placard(alt, yes);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
