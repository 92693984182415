.part-download-list {

  $imageWidth: 12rem;

  &>div {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  a {
    text-decoration: none;
    display: block;

    overflow: hidden;
    position: relative;
    @include use-icon('\e802');// icon-ok-circle
    &::before {
      position: absolute;
      font-size: 2rem;
      width: $imageWidth;
      text-align: center;
      transform: translateY(-2em);
      color: var(--bright-orange);
      transition: 1s transform;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    }

    &.clicked {
      opacity: 0.5;
      &::before {
        transform: translateY(calc(50% + 0.6em));
      }
    }
    &:hover {
      background: var(--cream);
    }
  }

  .text { @extend %trim-vertical-margins; }
  .text { @extend %vertical-spread; }

  article {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    gap: 1rem;
    .image {
      order: 1;
      display: grid;
      place-content: center;
      flex: 1 0 10rem;
      img {
        box-shadow: -4px 4px 0 var(--placard-bg-default);
        height: 9rem;
        transform: rotate(-1.3deg);
      }
    }
    .meta {
      order: 2;
      flex: 1000 0 20ch;
    }

    h1 {
      margin: 0;
      font-size: var(--font-size-larger);
    }
    p:last-child { margin-bottom: 0;}
  }
}

ul.downloads-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  &>li {
    border-bottom: solid 1px var(--bright-orange);
    padding: 1rem 0;
    &:last-child { border-bottom: none; }
  }
}
