.part-text-and-text {
  .cols-1-1>.text {
    container-type: inline-size;
  }
}
// @container (min-width: 1px) {
  .cols-1-1>.text {
      h2 { font-size: clamp(2rem, 5.3cqi, 3rem); }
      h3 { font-size: clamp(1.4rem, 4.5cqi, 2.4rem); }
    }
